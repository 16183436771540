import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ShellLayoutComponent } from "./theme/components/layout/shell-layout.component";
import { EmptyLayoutComponent } from "./theme/components/layout/empty-layout/empty-layout.component";
import { PatientRecordComponent } from "./pages/patient-record/patient-record.component";
import { AuthGuard } from "./core/authentication.guard";
import { PatientRecordResolver } from "./pages/patient-record/PatientRecord.resolver";
import { Role } from "./core/enum/Role";
import { PatientAppointmentsLayoutComponent } from "./theme/components/layout/patient-appointments-layout/patient-appointments-layout.component";
import { CompareLayoutComponent } from "./pages/patients/compare/compare-layout/compare-layout.component";
import { ListCatalogItemsComponent } from "./pages/catalog/list-catalog-items/list-catalog-items.component";

// prettier-ignore
const routes: Routes = [
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  {
    path: "",
    component: ShellLayoutComponent,
    children: [
      {
        path: "fitbit-callback",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/device-oauth-redirect/device-oauth-redirect.module").then((m) => m.DeviceOauthRedirectModule),
      },
      {
        path: "dashboard",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      // {
      //   path: "", canActivate: [AuthGuard],
      //   component: PatientsLayoutComponent,
      //   children: [
      // { path: "", redirectTo: "patients", pathMatch: "full" },
      {
        path: "patients",
        loadChildren: () => import("./pages/patients/patients.module").then((m) => m.PatientsModule),
      },
      {
        canActivate: [AuthGuard],
        path: "helpdesk",
        loadChildren: () => import("./pages/helpdesk/helpdesk.module").then((m) => m.HelpdeskModule),
      },
      {
        path: "patients-analysis",
        loadChildren: () =>
          import("./pages/patients-analysis/patients-analysis.module").then((m) => m.PatientsAnalysisModule),
      },
      {
        path: "leaderboard",
        loadChildren: () => import("./pages/leaderboard/leaderboard.module").then((m) => m.LeaderboardModule),
      },
      {
        path: "compare",
        component: CompareLayoutComponent,
        loadChildren: () => import("./pages/patients/compare/compare.module").then((m) => m.CompareModule),
      },
      {
        path: "search",
        loadChildren: () => import("./pages/patients/search/search.module").then((m) => m.SearchModule),
      },
      //   ]
      // },
      {
        path: "appointments",
        loadChildren: () => import("./pages/appointments/appointments.module").then((m) => m.AppointmentsModule),
      },
      {
        path: "nutrition-record-diary",
        loadChildren: () =>
          import("./pages/nutrition-record-diary/nutrition-record-diary.module").then(
            (m) => m.NutritionRecordDiaryModule
          ),
      },
      {
        path: "meals",
        loadChildren: () =>
          import("./pages/nutrition-meals/nutrition-meals.module").then((m) => m.NutritionMealsModule),
      },
      {
        path: "catalogue",
        loadChildren: () =>
          import("./pages/catalogue/catalogue.module").then((m) => m.CatalogueModule),
      },
      {
        path: "chat",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/chat/chat.module").then((m) => m.ChatModule),
      },
      {
        path: "education",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/configuration/education/education.module").then((m) => m.EducationModule),
        data: { roles: [Role.Doctor, Role.Patient, Role.CareCoordinator, Role.Admin] },
      },
      {
        path: "nutrition",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/configuration/nutrition/nutrition.module").then((m) => m.NutritionModule),
        data: { roles: [Role.Doctor] },
      },
      {
        path: "food-supplements",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/food-supplements/food-supplements.module").then((m) => m.FoodSupplementsModule),
        data: { roles: [Role.Doctor] },
      },
      {
        path: "notifications",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/notifications/notifications.module").then((m) => m.NotificationsModule),
      },
      {
        path: "critical-notifications",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/critical-notifications/critical-notifications.module").then(
            (m) => m.CriticalNotificationsModule
          ),
      },
      {
        path: "questionnaires",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/questionnaires/questionnaires.module").then((m) => m.QuestionnairesModule),
        data: { roles: [Role.Doctor, Role.Admin, Role.CareCoordinator] },
      },
      {
        path: "questionnaires/show",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/questionnaires/show/show.module").then((m) => m.QuestionnairesShowModule),
        data: { roles: [Role.Doctor, Role.Patient] },
      },
      {
        path: "account",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/acount/acount.module").then((m) => m.AcountModule),
      },
      {
        path: "dss",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/dss/dss.module").then((m) => m.DssModule),
        data: { roles: [Role.Doctor, Role.Admin, Role.CareCoordinator] },
      },
      {
        path: "mass-assign",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/mass-assign/mass-assign.module").then((m) => m.MassAssignModule),
        data: { roles: [Role.Doctor] },
      },
      {
        path: "maggic-calculator",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/maggic-calculator/maggic-calculator.module").then((m) => m.MaggicCalculatorModule),
        data: { roles: [Role.Doctor, Role.CareCoordinator] },
      },
      {
        path: "exercises",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/exercises/exercises.module").then((m) => m.ExercisesModule),
        data: { roles: [Role.Doctor, Role.CareCoordinator] },
      },
      {
        path: "patient-services",
        loadChildren: () =>
          import("./pages/patient-record/connected-services/connected-services.module").then(
            (m) => m.ConnectedServicesModule
          ),
      },
      {
        path: "admin",
        data: { roles: [Role.Admin] },
        canActivate: [AuthGuard],
        children: [
          {
            path: "all-users",
            loadChildren: () => import("./pages/all-users/all-users.module").then((m) => m.AllUsersModule),
          },
          {
            path: "doctor-questionnaires",
            loadChildren: () =>
              import("./pages/doctor-questionnaires/doctor-questionnaires.module").then(
                (m) => m.DoctorQuestionnairesModule
              ),
          },
          {
            path: "misbehaviour-reports",
            loadChildren: () =>
              import("./pages/misbehaviour-reports/misbehaviour-reports.module").then(
                (m) => m.MisbehaviourReportsModule
              ),
          },
          {
            path: "services",
            loadChildren: () =>
              import("./pages/catalog/list-catalog-items-admin/list-catalog-items-admin.module").then(
                (m) => m.ListCatalogItemsAdminModule
              ),
          },
        ],
      },
      {
        path: "",
        canActivate: [AuthGuard],
        component: PatientAppointmentsLayoutComponent,
        data: { roles: [Role.Patient] },
        children: [
          {
            path: "patient-appointments",
            loadChildren: () =>
              import("./pages/patient-appointments/patient-appointments.module").then(
                (m) => m.PatientAppointmentsModule
              ),
          },
          {
            path: "request-appointment",
            loadChildren: () =>
              import("./pages/patient-appointments/patient-appointment-list/patient-appointment-list.module").then(
                (m) => m.PatientAppointmentsListModule
              ),
          },
        ],
      },
      {
        path: "patients/patient-record/:patientId",
        component: PatientRecordComponent,
        canActivate: [AuthGuard],
        resolve: { patientInfo: PatientRecordResolver },
        children: [
          { path: "", redirectTo: "dashboard", pathMatch: "full" },
          {
            path: "history",
            loadChildren: () =>
              import("./pages/patient-record/patient-history/patient-history.module").then(
                (m) => m.PatientHistoryModule
              ),
          },
          {
            path: "patient-services",
            loadChildren: () =>
              import("./pages/patient-record/connected-services/connected-services.module").then(
                (m) => m.ConnectedServicesModule
              ),
          },
          {
            path: "dashboard",
            loadChildren: () =>
              import("./pages/patient-record/patient-dashboard/patient-dashboard.module").then(
                (m) => m.PatientDashboardModule
              ),
          },
          {
            path: "leaderboard",
            loadChildren: () => import("./pages/leaderboard/leaderboard.module").then((m) => m.LeaderboardModule),
          },
          // { path: "diagnoses", loadChildren: "./pages/patient-record/diagnoses/diagnoses.module#DiagnosesModule" },
          {
            path: "patient-notifications",
            loadChildren: () => import("./pages/notifications/notifications.module").then((m) => m.NotificationsModule),
          },
          {
            path: "careplan",
            loadChildren: () =>
              import("./pages/patient-record/care-plan/care-plan.module").then((m) => m.CarePlanModule),
          },
          {
            path: "patient-rules",
            loadChildren: () =>
              import("./pages/patient-record/patient-rules/patient-rules.module").then((m) => m.PatientRulesModule),
          },
          {
            path: "medical-record",
            loadChildren: () =>
              import("./pages/patient-record/medical-record/medical-record.module").then((m) => m.MedicalRecordModule),
          },
          {
            path: "nutritional-habits",
            loadChildren: () =>
              import("./pages/patient-record/nutritional-habits/nutritional-habits.module").then(
                (m) => m.NutritionalHabitsModule
              ),
          },
          {
            path: "devices",
            loadChildren: () =>
              import("./pages/patient-record/devices/devices.module").then((m) => m.PatientDevicesModule),
          },
          {
            path: "measurements",
            loadChildren: () =>
              import("./pages/patient-record/measurements/measurements.module").then(
                (m) => m.PatientMeasurementsModule
              ),
          },
          {
            path: "calendar",
            loadChildren: () =>
              import("./pages/patient-record/calendar-schedule/calendar-schedule.module").then(
                (m) => m.CalendarScheduleModule
              ),
          },
          {
            path: "diabetes-diagnosis",
            loadChildren: () =>
              import("./pages/diabetes-diagnosis/diabetes-diagnosis.module").then((m) => m.DiabetesDiagnosisModule),
          },
          {
            path: "volunteer-training",
            loadChildren: () =>
              import("./pages/patient-record/volunteer-training/volunteer-training.module").then(
                (m) => m.VolunteerTrainingModule
              ),
          },
          {
            path: "nutrition-notifications",
            loadChildren: () =>
              import("./pages/patient-record/nutrition-notifications/nutrition-notifications.module").then(
                (m) => m.NutritionNotificationsModule
              ),
          },
          {
            path: "risk-score",
            loadChildren: () =>
              import("./pages/patient-record/patient-risk-scores/patient-risk-scores.module").then(
                (m) => m.PatientRiskScoresModule
              ),
          },
        ],
      },
      // ------- Patient View -------
      {
        path: "patient-view",
        component: PatientRecordComponent,
        canActivate: [AuthGuard],
        resolve: { patientInfo: PatientRecordResolver },
        children: [
          { path: "", redirectTo: "dashboard", pathMatch: "full" },
          {
            path: "history",
            loadChildren: () =>
              import("./pages/patient-record/patient-history/patient-history.module").then(
                (m) => m.PatientHistoryModule
              ),
          },
          {
            path: "dashboard",
            loadChildren: () =>
              import("./pages/patient-record/patient-dashboard/patient-dashboard.module").then(
                (m) => m.PatientDashboardModule
              ),
          },
          // { path: "diagnoses", loadChildren: "./pages/patient-record/diagnoses/diagnoses.module#DiagnosesModule" },
          {
            path: "patient-notifications",
            loadChildren: () => import("./pages/notifications/notifications.module").then((m) => m.NotificationsModule),
          },
          {
            path: "careplan",
            loadChildren: () =>
              import("./pages/patient-record/care-plan/care-plan.module").then((m) => m.CarePlanModule),
          },
          {
            path: "leaderboard",
            loadChildren: () => import("./pages/leaderboard/leaderboard.module").then((m) => m.LeaderboardModule),
          },
          {
            path: "patient-rules",
            loadChildren: () =>
              import("./pages/patient-record/patient-rules/patient-rules.module").then((m) => m.PatientRulesModule),
          },
          {
            path: "medical-record",
            loadChildren: () =>
              import("./pages/patient-record/medical-record/medical-record.module").then((m) => m.MedicalRecordModule),
          },
          {
            path: "nutritional-habits",
            loadChildren: () =>
              import("./pages/patient-record/nutritional-habits/nutritional-habits.module").then(
                (m) => m.NutritionalHabitsModule
              ),
          },
          {
            path: "devices",
            loadChildren: () =>
              import("./pages/patient-record/devices/devices.module").then((m) => m.PatientDevicesModule),
          },
          {
            path: "my-doctors",
            loadChildren: () =>
              import("./pages/patient-record/my-doctors/my-doctors.module").then((m) => m.MyDoctorsModule),
          },
          {
            path: "measurements",
            loadChildren: () =>
              import("./pages/patient-record/measurements/measurements.module").then(
                (m) => m.PatientMeasurementsModule
              ),
          },
          {
            path: "calendar",
            loadChildren: () =>
              import("./pages/patient-record/calendar-schedule/calendar-schedule.module").then(
                (m) => m.CalendarScheduleModule
              ),
          },
          {
            path: "patient-services",
            loadChildren: () =>
              import("./pages/patient-record/connected-services/connected-services.module").then(
                (m) => m.ConnectedServicesModule
              ),
          },
          {
            path: "volunteer-training",
            loadChildren: () =>
              import("./pages/patient-record/volunteer-training/volunteer-training.module").then(
                (m) => m.VolunteerTrainingModule
              ),
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: EmptyLayoutComponent,
    children: [
      {
        path: "login",
        loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginModule),
      },
      {
        path: "register",
        loadChildren: () => import("./pages/registration/registration.module").then((m) => m.RegistrationModule),
      },
      {
        path: "reset-password",
        loadChildren: () => import("./pages/reset-password/reset-password.module").then((m) => m.ResetPasswordModule),
      },
      {
        path: "register-caregiver",
        loadChildren: () =>
          import("./pages/register-caregiver/register-caregiver.module").then((m) => m.RegisterCaregiverModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
