<aside [@slideInOut]="isOpen" [ngClass]="[isOpen == 'out' ? 'minified' : 'collapsed']">
  <div class="d-flex flex-column" style="height: 100%; overflow-y: auto !important">
    <div class="sidebar-header">
      <img src="/assets/images/favicon.png" alt="" height="54" />
    </div>
    <div class="menu flex-grow-1 sidebar-item sidebar-menu">
      <ul>
        <li *ngIf="userRole !== 'ROLE_PATIENT' && userRole !== 'ROLE_ADMIN' && userRole !== 'ROLE_DOCTOR'">
          <div>
            <a routerLink="#" routerLinkActive="active" [routerLink]="['/dashboard']">
              <i class="fas fa-tachometer-alt"></i>
              <span *ngIf="isOpen == 'in'">{{ "Dashboard" | translate }}</span>
            </a>
          </div>
        </li>
        <li *ngFor="let item of menu" [ngClass]="{ 'not-hover': item.subMenuItems }">
          <div *ngIf="!item.subMenuItems">
            <a [routerLink]="item.routerLink" routerLinkActive="active" (click)="sendGoogleAnalytics(item.routerLink)">
              <i
                *ngIf="item.iconClass && item.iconClass != ''"
                [ngClass]="item.iconClass"
                class="fa-fw fas fa-bars"
              ></i>
              <span *ngIf="isOpen == 'in'">{{ item.text | translate }}</span>
            </a>
          </div>
          <div *ngIf="item.subMenuItems" class="">
            <div class="sub-menu">
              <i
                *ngIf="item.iconClass && item.iconClass != ''"
                [ngClass]="item.iconClass"
                class="fa-fw fas fa-bars"
              ></i>
              <span *ngIf="isOpen == 'in'">{{ item.text | translate }}</span>
            </div>
            <div>
              <ul>
                <li *ngFor="let subItem of item.subMenuItems">
                  <a
                    [routerLink]="subItem.routerLink"
                    routerLinkActive="active"
                    style="padding-left: 30px !important; font-size: 14px !important"
                  >
                    <i
                      *ngIf="subItem.iconClass && subItem.iconClass != ''"
                      [ngClass]="subItem.iconClass"
                      class="fa-fw fas fa-bars"
                    ></i>
                    <span *ngIf="isOpen == 'in'">{{ subItem.text | translate }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div>
            <a routerLink="#" routerLinkActive="active" (click)="logoutUser()">
              <i class="fas fa-sign-out-alt"></i>
              <span *ngIf="isOpen == 'in'">{{ "sidebar.Logout" | translate }}</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
    <div *ngIf="isOpen === 'in'" class="hl7-logo">
      <img src="/assets/images/hl7-logo.png" />
    </div>
  </div>
</aside>
